import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import Popup from "reactjs-popup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import QuestionComponent from "./component/q_n_a_component";
import { Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import './q_n_a_screen.css';


const QuestionScreen = () => {
  const [loading, setLoading] = useState(false);
  const [questionDetails, setQuestionDetails] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [newQuestionDetails, setNewQuestionDetails] = useState({
    qanda_question: "",
    qanda_answer: "",
    subject: "",
  });
  const [errors, setErrors] = useState({
    qanda_question: "",
    qanda_answer: "",
    subject: "",
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuestionDetails({
      ...newQuestionDetails,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", 
    });
  };

  const handleSubjectChange = (e) => {
    setNewQuestionDetails({
      ...newQuestionDetails,
      subject: e.target.value,
    });
    setErrors({
      ...errors,
      subject: "", 
    });
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    
    // Validation logic
    const newErrors = {};
    if (!newQuestionDetails.qanda_question) {
      newErrors.qanda_question = "Question is required";
    }
    if (!newQuestionDetails.qanda_answer) {
      newErrors.qanda_answer = "Answer is required";
    }
    if (!newQuestionDetails.subject) {
      newErrors.subject = "Subject is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop form submission if there are validation errors
    }

    handleAddQuestionDetails(close);
  };

  const handleAddQuestionDetails = (close) => {
    const formData = new FormData();
    formData.append("qanda_question", newQuestionDetails.qanda_question);
    formData.append("qanda_answer", newQuestionDetails.qanda_answer);
    formData.append("subject", newQuestionDetails.subject);

    axios
      .post(baseUrl + `app/QnA/create/`, formData, config)
      .then((response) => {
        setQuestionDetails([...questionDetails, response.data]);
        setNewQuestionDetails({
          qanda_question: "",
          qanda_answer: "",
          subject: "",
        });
        close();
      })
      .catch((error) => {
        console.error("Add queation Error:", error);
      });
  };

  const handleEditQuestionDetails = (
    questionId,
    editedQuestionDetails,
    close
  ) => {
    const formData = new FormData();
    formData.append("qanda_question", editedQuestionDetails.qanda_question);
    formData.append("qanda_answer", editedQuestionDetails.qanda_answer);
    formData.append("subject", editedQuestionDetails.subject);

    axios
      .patch(baseUrl + `app/QnA/update/${questionId}/`, formData, config)
      .then((response) => {
        setQuestionDetails((prevQuestionDetails) =>
          prevQuestionDetails.map((qa) =>
            qa.note_id === questionId ? response.data : qa
          )
        );
        fetchQuestions();
        close();
      })
      .catch((error) => {
        console.error("Edit Note Error:", error);
      });
  };

  const deleteNote = (questionId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/QnA/delete/${questionId}/`, config)
      .then((response) => {
        setQuestionDetails((prevQuestion) =>
          prevQuestion.filter((qa) => qa.id !== questionId)
        );
      })
      .catch((error) => {
        console.error("Exam Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchQuestions = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/QnA/list/`, config)
      .then((response) => {
        setQuestionDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Note details Error:", error);
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, config)
      .then((response) => {
        const subjects = response.data.results.flatMap((course) =>
          course.course_subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.Subject_name,
          }))
        );
        setSubjectList(subjects);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subject list:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchQuestions();
    fetchSubjectList();
  }, []);

  const groupQuestionsBySubject = () => {
    return questionDetails.reduce((acc, questions) => {
      const subjectId = questions.subject;
      if (!acc[subjectId]) {
        acc[subjectId] = [];
      }
      acc[subjectId].push(questions);
      return acc;
    }, {});
  };

  const groupedQuestions = groupQuestionsBySubject();

  // console.log("Grouped Question:", groupedQuestions);

  return (
    <div className="main-container">
      <Container fluid>
        <h2 className="accordion-head m-3">Subjects with Q and A</h2>
        {loading ? (
          <Spinner className="loader-spinner" />
        ) : Object.keys(groupedQuestions).length > 0 ? (
          <Accordion>
            {Object.keys(groupedQuestions).map((subjectId, idx) => {
              const subjectName =
                subjectList.find(
                  (subject) => subject.id === parseInt(subjectId)
                )?.name || "Unknown Subject";

              return (
                <Accordion.Item eventKey={idx.toString()} key={subjectId}>
                  <Accordion.Header>{subjectName}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {groupedQuestions[subjectId].map((question) => (
                        <Col lg={6} key={question.qanda_id}>
                          <QuestionComponent
                            id={question.qanda_id}
                            key={question.qanda_id}
                            question={question.qanda_question}
                            answer={question.qanda_answer}
                            subjectId={question.subject}
                            subject_name={
                              subjectList.find(
                                (subject) => subject.id === question.subject
                              )?.name || ""
                            }
                            subjectList={subjectList}
                            onEditNote={handleEditQuestionDetails}
                            onDelete={deleteNote}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div>Q and is not available</div>
        )}

        <Popup
          className="add-popup-container"
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add Q & A</button>}
          modal
          nested
        >
          {(close) => (
            <div className="add-course-div">
              <form onSubmit={(e) => handleSubmit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Add Question Details</div>
                  <div className="course-container">
                    <TextField
                      className="textfields"
                      name="qanda_question"
                      label="Question"
                      variant="outlined"
                      size="small"
                      value={newQuestionDetails.qanda_question}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!errors.qanda_question}
                      helperText={errors.qanda_question}
                    />
                    <TextField
                      className="textfields"
                      name="qanda_answer"
                      label="Answer"
                      variant="outlined"
                      size="small"
                      value={newQuestionDetails.qanda_answer}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!errors.qanda_answer}
                      helperText={errors.qanda_answer}
                    />
                    <select
                      name="subject"
                      className="form-control"
                      value={newQuestionDetails.subject}
                      onChange={handleSubjectChange}
                      disabled={loading}
                    >
                      <option value="">Select Subject</option>
                      {subjectList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.subject && <div className="error-text">{errors.subject}</div>}
                  </div>
                  <div className="submit-banner-button-container">
                    <button type="submit" disabled={loading}>
                      Submit
                    </button>
                    <button type="button" onClick={close} disabled={loading}>
                      Cancel
                    </button>
                  </div>
                </Box>
              </form>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default QuestionScreen;
