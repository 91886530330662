import "./my_appbar.css";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Logo from "./../../../assets/IQWin.svg";
import AppConstants from "./../../AppConstants";
import { menuList } from "./../menu/menu_data";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function MyAppBar({ open, selectedItemFromMenu, sendDrawerOpen }) {
  const navigate = useNavigate();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: AppConstants.drawerWidth,
      width: `calc(100% - ${AppConstants.drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    background: "#f4eff5",
  }));

  const handleDrawerOpen = () => {
    sendDrawerOpen();
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("../", { replace: true });
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="#796dec"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {!open && (
          <img alt="logo" src={Logo} className="brand-logo me-3" />
        )}
        <Container className="app-bar">
          <Typography variant="h6" noWrap component="div">
            {menuList[selectedItemFromMenu].name}
          </Typography>
          <div className="b-name">
            <span className="material-icons" onClick={() => {}}>
              store
            </span>
            <div className="business-title ps-2 pe-5">IQWIN Adminpanel</div>
            <div className="logout" onClick={handleLogout}>
              <span className="logout-icon material-icons">logout</span>
              <div className="logout-text ps-2">Logout</div>
            </div>
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default MyAppBar;
