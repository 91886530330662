import "./../login/login.css";

import "./splash.css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./../../assets/IQWin.svg";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import AppConstants from "../AppConstants";

function Splash() {
  const navigate = useNavigate();

  useEffect(() => {
  

    setTimeout(() => {
      let authToken = localStorage.getItem(AppConstants.JWT);
      if (authToken === null) {
       
        axios.defaults.headers.common.Authorization = null;
        navigate("../login", { replace: true });
      } else {
     
        axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        navigate("../home", { replace: true });
      }
    }, 4000);
  });

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <img alt="logo" src={Logo} className="logo"/>
         

          <TailSpin
            className="splash-spinner"
            height="30"
            width="30"
            radius="1"
            color="#796dec"
            ariaLabel="loading"
            visible={true}
            // wrapperStyle
            // wrapperClass
          />
        </div>
      </div>

      <a href="tel:+919481815122" className="contact">
        ⓘ Contact Us
      </a>
    </section>
  );
}

export default Splash;
