import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Popup from "reactjs-popup";
import { TextField, Button } from "@mui/material";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Box from "@mui/material/Box";
import "./exam_component.css";

const ExamComponent = ({
  id,
  title,
  instruction,
  duration,
  score,
  subject_name,
  subjectList,
  chapterList,
  chapterId,
  subjectId,
  solution,
  showPdf,
  onExamClick,
  onEditExam,
  onDelete,
}) => {
  const [editingExam, setEditingExam] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedExamDetails, setEditedExamDetails] = useState({
    exam_title: title,
    exam_instructions: instruction,
    exam_duration: duration,
    max_score: score,
    subject: subjectId,
    chapter: chapterId,
    exam_solutions: solution,
  });
  const [errors, setErrors] = useState({});

  const handleExamChange = (event) => {
    const { name, value } = event.target;
    setEditedExamDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFileChange = (event) => {
    setEditedExamDetails((prevDetails) => ({
      ...prevDetails,
      exam_solutions: event.target.files[0],
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      exam_solutions: "",
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    if (!editedExamDetails.exam_title) newErrors.exam_title = "Exam Title is required.";
    if (!editedExamDetails.exam_duration) newErrors.exam_duration = "Exam Duration is required.";
    if (!editedExamDetails.max_score) newErrors.max_score = "Max Score is required.";
    if (!editedExamDetails.subject) newErrors.subject = "Subject is required.";
    if (!editedExamDetails.chapter) newErrors.chapter = "Chapter is required.";
    if (!editedExamDetails.exam_instructions) newErrors.exam_instructions = "Instructions are required.";
    if (editedExamDetails.exam_solutions && editedExamDetails.exam_solutions.size > 5000000) {
      newErrors.exam_solutions = "File size should be less than 5MB.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
    onEditExam(id, editedExamDetails, close);
    setEditingExam(false);
  }
  };
  const chapter = chapterList.find((chapter) => chapter.id === parseInt(chapterId))
  const chapterName = chapter ?chapter.session : "Unknown session";

  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  const handleExamClick = () => {
    onExamClick();
  };


  return (
    <Card className="exam-card m-2" onClick={handleExamClick}>
      <Card.Body className="card-texts">
        <Card.Text className="course">
          <Row>
            <Col lg={3} className=" mt-lg-1">
              <div className="subject-span">Exam Title:</div>
            </Col>
            <Col lg={9}>
              <div className=" mt-lg-1">{title}</div>
            </Col>
            <Col lg={3} className=" mt-lg-2">
              <div className="subject-span">Exam Duration:</div>
            </Col>
            <Col lg={9}>
              <div className=" mt-lg-2">{duration}</div>
            </Col>
            <Col lg={3} className=" mt-lg-2">
              <div className="subject-span">Max Score:</div>
            </Col>
            <Col lg={9}>
              <div className=" mt-lg-2">{score}</div>
            </Col>
            <Col lg={3} className=" mt-lg-2">
              <div className="subject-span">Subject:</div>
            </Col>
            <Col lg={9}>
              <div className=" mt-lg-2">{subject_name}</div>
            </Col>
            <Col lg={3} className=" mt-lg-2">
              <div className="subject-span">Chapter:</div>
            </Col>
            <Col lg={9}>
              <div className=" mt-lg-2">{chapterName}</div>
            </Col>
            <Col lg={3} className="mt-lg-2">
              <div className="subject-span">Exam Instruction:</div>
            </Col>
            <Col lg={9} className="mt-lg-2">
              {Array.isArray(instruction) ? (
                instruction.map((instr, index) => (
                  <div key={index} className="exam-span-text">
                    {instr}
                  </div>
                ))
              ) : (
                <div>{instruction}</div>
              )}
            </Col>
            <Col lg={3} className=" mt-lg-2">
              <div className="subject-span">Exam Solution:</div>
            </Col>
            <Col lg={9} className="mt-lg-2">
              {/* <button onClick={() => showPdf(solution)} className="mcq-submit">
                Show pdf
              </button> */}
              <a href={solution}>{solution}</a>
            </Col>
          </Row>
        </Card.Text>
        <div className="course-edit-delete">
          <Popup
            className="add-popup-container"
            trigger={
              <button className="course-edit">
                <MdEdit />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form onSubmit={(event) => handleSubmitEdit(event, close)}>
                  <Box className="popup-box">
                    <div className="heading">Edit Exam Details</div>
                    <div className="course-container">
                      <TextField
                        className="textfields"
                        name="exam_title"
                        label="Exam Title"
                        variant="outlined"
                        size="small"
                        value={editedExamDetails.exam_title}
                        onChange={handleExamChange}
                        error={Boolean(errors.exam_title)}
                        helperText={errors.exam_title}
                      />
                      <TextField
                        className="textfields"
                        name="exam_instructions"
                        label="Exam Instructions"
                        variant="outlined"
                        size="small"
                        multiline
                        rows={3}
                        value={
                          Array.isArray(editedExamDetails.exam_instructions)
                            ? editedExamDetails.exam_instructions.join("\n")
                            : editedExamDetails.exam_instructions
                        }
                        onChange={handleExamChange}
                        error={Boolean(errors.exam_instructions)}
                        helperText={errors.exam_instructions}
                      />
                      <TextField
                        className="textfields"
                        name="exam_duration"
                        label="Exam Duration"
                        variant="outlined"
                        size="small"
                        value={editedExamDetails.exam_duration}
                        onChange={handleExamChange}
                        error={Boolean(errors.exam_duration)}
                        helperText={errors.exam_duration}
                      />
                      <TextField
                        className="textfields"
                        name="max_score"
                        label="Max Score"
                        variant="outlined"
                        size="small"
                        value={editedExamDetails.max_score}
                        onChange={handleExamChange}
                        error={Boolean(errors.max_score)}
                        helperText={errors.max_score}
                      />
                      <select
                        name="subject"
                        className="form-control"
                        value={editedExamDetails.subject}
                        onChange={handleExamChange}
                      >
                        <option value="">Select Subject</option>
                        {subjectList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {errors.subject && <div className="error-text">{errors.subject}</div>}
                      <select
                        name="chapter"
                        className="form-control"
                        value={editedExamDetails.chapter}
                        onChange={handleExamChange}
                      >
                        <option value="">Select Chapter</option>
                        {chapterList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.session}
                          </option>
                        ))}
                      </select>
                      {errors.chapter && <div className="error-text">{errors.chapter}</div>}
                      <span>Exam pdf</span>
                      <label className="add-file-upload">
                        <input
                          className="add-img-file"
                          name="exam_solutions"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <MdOutlineFileUpload className="upload-icon" />
                      </label>
                      {errors.exam_solutions && <div className="error-text">{errors.exam_solutions}</div>}
                    </div>
                    <div className="submit-banner-button-container">
                      <button type="submit">Submit</button>
                      <button type="button" onClick={close}>
                        Cancel
                      </button>
                    </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>

          <Popup
            className="popup-container"
            contentStyle={{
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            trigger={
              <button className="course-delete">
                <MdDeleteForever />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">Are you sure?</h3>
                <p className="delete-img-p">Do you really want to delete?</p>
                <div className="delete-btn-div">
                  <button onClick={handleConfirmDelete}>Delete</button>
                  <button onClick={close}>Cancel</button>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExamComponent;
