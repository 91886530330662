import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { menuList } from './menu_data';
import List from "@mui/material/List";

function Menu({ open, sendSelectedItemToParent }) {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    sendSelectedItemToParent(index);
  };

  return (
    <List>
      {menuList.map((obj, index) => (
        <ListItem key={index} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedItem === index}
            onClick={() => handleItemClick(index)}
            aria-label={`Menu item: ${obj.name}`}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              backgroundColor: "white",
              "&.Mui-selected": {
                backgroundColor: "#e3e3e3",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <span className="material-icons">{obj.icon}</span>
            </ListItemIcon>
            <ListItemText primary={obj.name} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default Menu;
