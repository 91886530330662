export const menuList = [
  {
    name: "Courses",
    icon: "school",
  },
  {
    name: "Chapter",
    icon: "pages",
  },
  {
    name: "Faculties",
    icon: "person",
  },
  {
    name: "Recorded class",
    icon: "class",
  },
  {
    name: "Exams",
    icon: "edit",
  },
  {
    name: "Notes",
    icon: "note",
  },
  {
    name: "Q and A",
    icon: "quiz",
  },
  {
    name: "Course purchase",
    icon: "list",
  },
  {
    name: "Student Details & Progress",
    icon: "circle",
  },
  {
    name: "Referal",
    icon: "style",
  },

    {
      name: "Banner",
      icon: "image",
    },     
  ];