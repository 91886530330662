

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Popup from "reactjs-popup";
import { Form, Button } from "react-bootstrap";
import "./question_exam_component.css";
import config from "../../../../../../network/auth";
import axios from "../../../../../../network/api";
import { baseUrl } from "../../../../../../network/constants/constants";
import Box from "@mui/material/Box";

const QuestionExamComponent = ({
  id,
  questionText,
  questionImage,
  options,
  answer,
  onEditQuestion,
  onDelete,
}) => {

  const [editingQuestion, setEditingQuestion] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState({
    questionText: questionText || "",
    options: options || [],
    answer: answer || "",
    questionImage: questionImage,
  });
  useEffect(() => {
    setEditedQuestion({
      questionText: questionText || "",
      options: options || [],
      answer: answer || "",
      answerText: answerOption ? answerOption.optionText : "",
      questionImage: questionImage,
    });
  }, [questionText, options, answer, questionImage]);

  const handleQuestionChange = (e) => {
    setEditedQuestion({
      ...editedQuestion,
      questionText: e.target.value,
    });
  };

  const handleOptionChange = (index, e) => {
    const updatedOptions = editedQuestion.options.map((option, i) =>
      i === index ? { ...option, optionText: e.target.value } : option
    );
    setEditedQuestion({
      ...editedQuestion,
      options: updatedOptions,
    });
  };

  const handleAnswerChange = (e) => {
    setEditedQuestion({
      ...editedQuestion,
      answer: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:", file);
    setEditedQuestion({
      ...editedQuestion,
      questionImage: file,
    });
  };

  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
      onEditQuestion(id, editedQuestion, close);
    setEditingQuestion(false);
    close();
  };

  const handleConfirmDelete = (close) => {
    onDelete(id);
    close();
  };


  

  const answerOption = options.find((option) => option.id === answer);
  const answerText = answerOption
    ? answerOption.optionText
    : "Answer not found";
  return (
    <div>
      <Card className="mcq-card">
        <Container>
          <Row>
            <Col lg={2} className="mt-3 mt-lg-5">
              <div className="key-label">Question</div>
            </Col>
            <Col lg={10}>
              <div className="mt-3 mt-lg-5">{questionText}</div>
            </Col>
            {questionImage && (
              <>
                <Col lg={2} className="mt-3 mt-lg-5">
                  <div className="key-label">Question Image</div>
                </Col>
                <Col lg={10}>
                  <div className="mt-3 mt-lg-5">
                    <a href={questionImage}>
                    <img
                      src={questionImage}
                      alt="Question"
                      className="question-image"
                    />
                    </a>
                  </div>
                </Col>
              </>
            )}
          </Row>
          <Row>
            {options.map((option, index) => (
              <Col lg={6} key={option.id}>
                <Container>
                  <Row>
                    <Col lg={4} className="mt-3 mt-lg-5">
                      <div className="key-label">Option {index + 1}</div>
                    </Col>
                    <Col lg={8}>
                      <div className="mt-3 mt-lg-5">
                        <div className="option-text-image">
                          {option.optionText}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={2} className="mt-3 mt-lg-5">
              <div className="key-label">Answer</div>
            </Col>
            <Col lg={10}>
              <div className="mt-3 mt-lg-5">{answerText}</div>
            </Col>
          </Row>
        </Container>
        <div className="course-edit-delete">
          <Popup
            className="add-popup-container"
            trigger={
              <button className="course-edit">
                <MdEdit />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Edit Question Details</div>

                  <Form.Group controlId="questionText">
                    <Form.Label>Question Text</Form.Label>
                    <Form.Control
                      type="text"
                      value={editedQuestion.questionText}
                      onChange={handleQuestionChange}
                      placeholder="Enter question text"
                    />
                  </Form.Group>
                  {editedQuestion.options.map((option, index) => (
                    <Form.Group key={index} controlId={`option${index}`}>
                      <Form.Label>Option {index + 1}</Form.Label>
                      <Form.Control
                        type="text"
                        value={option.optionText}
                        onChange={(e) => handleOptionChange(index, e)}
                        placeholder={`Enter option ${index + 1}`}
                      />
                    </Form.Group>
                  ))}
                  <Form.Group controlId="answer">
                    <Form.Label>Answer</Form.Label>
                    <Form.Control
                      as="select"
                      value={editedQuestion.answer}
                      onChange={handleAnswerChange}
                    >
                      {editedQuestion.options.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.optionText}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="questionImage">
                    <Form.Label>Question Image</Form.Label>
                      <Form.Control type="file" onChange={handleImageChange} />
                  </Form.Group>
                  <div className="submit-banner-button-container">
                    <button type="submit">Submit</button>
                    <button type="button" onClick={close}>
                      Cancel
                    </button>
                  </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>

          <Popup
              className="popup-container"
              contentStyle={{
                borderRadius: "20px",
                backgroundColor: "white",
              }}
              trigger={
                <button className="course-delete">
                  <MdDeleteForever />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="delete-img-div">
                  <h3 className="delete-img-heading">Are you sure?</h3>
                  <p className="delete-img-p">Do you really want to delete?</p>
                  <div className="delete-btn-div">
                    <button onClick={() => handleConfirmDelete(close)}>
                      Delete
                    </button>
                    <button onClick={close}>Cancel</button>
                  </div>
              </div>
            )}
          </Popup>
        </div>
      </Card>
    </div>
  );
};
export default QuestionExamComponent;




