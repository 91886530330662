
import React, { useState, useEffect } from "react";
import "./question_exam.css";
import { Form, Button, Container } from "react-bootstrap";
import QuestionExamComponent from "./component/question_exam_component";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import { Spinner } from "react-bootstrap";

const QuestionExam = ({ onPageChange, examId }) => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    options: [
      { id: 1, optionText: "" },
      { id: 2, optionText: "" },
      { id: 3, optionText: "" },
      { id: 4, optionText: "" },
    ],
    answer: "",
    questionImage: null,
  });
  const [currentAnswer, setCurrentAnswer] = useState(null);

  const handleQuestionChange = (e) => {

    setNewQuestion({
      ...newQuestion,
      questionText: e.target.value,
    });
  };

  const handleOptionChange = (index, e) => {
    const updatedOptions = newQuestion.options.map((option, i) =>
      i === index ? { ...option, optionText: e.target.value } : option
    );
    setNewQuestion({
      ...newQuestion,
      options: updatedOptions,
    });

  };

  const handleAnswerChange = (e) => {
    const selectedAnswer = e.target.value
    setNewQuestion({
      ...newQuestion,
      answer: selectedAnswer,
    });
    setCurrentAnswer(selectedAnswer);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewQuestion({
        ...newQuestion,
        questionImage: file,
      });
    } else {
      console.warn("No file selected or file is invalid");
    }
  };

  const handleAddQuestion = (e, close) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("question", newQuestion.questionText);
    formData.append("option1", newQuestion.options[0].optionText);
    formData.append("option2", newQuestion.options[1].optionText);
    formData.append("option3", newQuestion.options[2].optionText);
    formData.append("option4", newQuestion.options[3].optionText);
    formData.append("answer", newQuestion.answer);

    console.log(newQuestion.options[0].optionText)
    console.log(newQuestion.options[1].optionText)
    console.log(newQuestion.options[2].optionText)
    console.log(newQuestion.options[3].optionText)
    console.log("answer "+ newQuestion.answer);

    if (newQuestion.questionImage) {
      formData.append("question_image", newQuestion.questionImage);
    }
    formData.append("link_type", "ext_link");

    axios

      .post(`${baseUrl}app/question-and-option/${examId}/`, formData, config)

      .then((response) => {
        console.log("Question added successfully:", response.data);
        fetchQuestions();
        setQuestions([...questions, response.data]);
        setNewQuestion({
          questionText: "",
          options: [
            { id: 1, optionText: "" },
            { id: 2, optionText: "" },
            { id: 3, optionText: "" },
            { id: 4, optionText: "" },
          ],
          answer: "",
          questionImage: null,
        });
        close();
      })
      .catch((error) => {
        console.error("Submit Question Error:", error.message);
        if (error.response) {
          console.error("Error response data:", error.response.data);
        }
      });
  };



  const handleEditQuestion = (questionId, editedQuestion, close) => {
    const selectedOption = editedQuestion.options.find(
      (option) => option.id === parseInt(editedQuestion.answer, 10)
    );
  
    const formData = new FormData();
    formData.append("question", editedQuestion.questionText);
    formData.append("option1", editedQuestion.options[0].optionText);
    formData.append("option2", editedQuestion.options[1].optionText);
    formData.append("option3", editedQuestion.options[2].optionText);
    formData.append("option4", editedQuestion.options[3].optionText);
    formData.append("answer", selectedOption ? selectedOption.optionText : editedQuestion.answer);
  
    
    
    if (editedQuestion.questionImage instanceof File) {
      formData.append("question_image", editedQuestion.questionImage);
    } 
    
    formData.append("link_type", "ext_link");
    axios
      .put(
        `${baseUrl}app/question-and-option-edit/${questionId}/`,
        formData,
        config
      )
      .then((response) => {
        // console.log("Question edited successfully:", response.data);
        
        fetchQuestions();
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === questionId ? response.data : question
          )
        );
        close();
      })
      .catch((error) => {
        console.error("Edit Question Error:", error);
      });
  };
  
  
  const deleteQuestion = (questionId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/question-delete/${questionId}/`, config)
      .then((response) => {
        setQuestions((prevQuestions) =>
          prevQuestions.filter((question) => question.id !== questionId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Delete Question Error:", error);
        setLoading(false);
      });
  };

  // Fetch questions
  const fetchQuestions = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/exam-complete/${examId}/`, config)
      .then((response) => {
        setQuestions(response.data.questions);
        // console.log('fetch data',response.data.questions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fetch Questions Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchQuestions();
  }, [examId]);

  const handleExamClick = () => {
    onPageChange("exam");
  };

  return (
    <Container fluid>
    <div className="main-container">
      <div className="go-back-div">
        <Button className="go-back" onClick={handleExamClick}>
          Go Back
        </Button>
      </div>
      <div className="submcq-component-container">
      {loading ? (
          <Spinner className="loader-spinner" />
        ) : (
          questions.map((question) => (
            <QuestionExamComponent
              key={question.id}
              id={question.id}
              questionText={question.question_text}
              questionImage={question.question_image}
              options={question.options.map((option) => ({
                id: option.id,
                optionText: option.Option_text,
              }))}
              answer={question.question_answer}
              onDelete={deleteQuestion}
              onEditQuestion={handleEditQuestion}
            />
          ))
        )}
      </div>
      <Popup
        className="add-popup-container"
        trigger={<button className="btn-add">+ Add Questions</button>}
        modal
        nested
      >
        {(close) => (
          <div className="add-course-div">
            <form onSubmit={(e) => handleAddQuestion(e, close)}>
            <Box className="popup-box">
              <div className="heading">Add Question Details</div>
              <Form.Group controlId="questionText">
                <Form.Label>Question Text</Form.Label>
                <Form.Control
                  type="text"
                  value={newQuestion.questionText}
                  onChange={handleQuestionChange}
                  placeholder="Enter question text"
                />
              </Form.Group>

              {newQuestion.options.map((option, index) => (
                <Form.Group key={index} controlId={`option${index}`}>
                  <Form.Label>Option {index + 1}</Form.Label>
                  <Form.Control
                    type="text"
                    value={option.optionText}
                    onChange={(e) => handleOptionChange(index, e)}
                    placeholder={`Enter option ${index + 1}`}
                  />
                </Form.Group>
              ))}
              <Form.Group controlId="answer">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="select"
                  value={newQuestion.answer}
                  onChange={handleAnswerChange}
                >
                  <option value="select" >
                    Select an Answer
                  </option>
                   {newQuestion.options.map((option, index) => (
                    <option key={index} value={option.optionText}>
                      Option {option.id}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="questionImage">
                <Form.Label>Question Image</Form.Label>
                <Form.Control type="file" onChange={handleImageChange} />
              </Form.Group>
              <div className="submit-banner-button-container">
                <button type="submit">Submit</button>
                <button type="button" onClick={close}>
                  Cancel
                </button>
              </div>
              </Box>
            </form>
          </div>
        )}
      </Popup>
    </div>
    </Container>
  );
};

export default QuestionExam;






