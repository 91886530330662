import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./faculty_component.css";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import avathar from "./../../../../../assets/avathar.jpg";

const FacultyComponent = ({
  id,
  name,
  photo,
  description,
  referral_id,
  expertise,
  referral_count,
  onEditFaculty,
  onDelete,
}) => {
  const [editingFaculty, setEditingFaculty] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedFaculty, setEditedFaculty] = useState({
    faculty_name: name,
    faculty_image: photo,
    faculty_description: description,
    faculty_referral_id: referral_id,
    faculty_expertise: expertise,
    faculty_referral_count: referral_count,
  });
  const [validationErrors, setValidationErrors] = useState({
    faculty_name: "",
    faculty_description: "",
    faculty_expertise: "",
    faculty_image: "",
  });
  const [selectedFileName, setSelectedFileName] = useState("");

  const validateFaculty = () => {
    const errors = {};
  
  
    if (!editedFaculty.faculty_name) {
      errors.faculty_name = "Faculty name is required";
    }
  

    if (!editedFaculty.faculty_description) {
      errors.faculty_description = "Description is required";
    } else if (editedFaculty.faculty_description.length < 8) {
      errors.faculty_description = "Description must be at least 8 characters long";
    }
  

    if (!editedFaculty.faculty_expertise) {
      errors.faculty_expertise = "Expertise is required";
    } else if (editedFaculty.faculty_expertise.length < 8) {
      errors.faculty_expertise = "Expertise must be at least 8 characters long";
    }
  
    
   
  
    return errors;
  };
  
  const handleFacultyChange = (event) => {
    setEditedFaculty({
      ...editedFaculty,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setEditedFaculty({
      ...editedFaculty,
      faculty_image: file,
    });
    setSelectedFileName(file.name);
  };

  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    const errors = validateFaculty();
    if (Object.keys(errors).length === 0) {
      onEditFaculty(id, editedFaculty, close);
      setEditingFaculty(false);
    } else {
      setValidationErrors(errors);
    }
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  return (
    <div>
      <Card className="faculty-card">
        <Card.Img className="faculty-image" src={photo || avathar} alt="faculty image" />
        <hr />
        <Card.Body className="card-texts">
          <Card.Text className="faculty">
         
             <Row>
              <Col lg={3} className=" mt-lg-1">
                <div className="subject-span">Faculty Name:</div>
              </Col>
              <Col lg={9}>
                <div className=" mt-lg-1">{name}</div>
              </Col>
              <Col lg={3} className=" mt-lg-2">
                <div className="subject-span">Description:</div>
              </Col>
              <Col lg={9}>
                <div className=" mt-lg-2">{description}</div>
              </Col>
              <Col lg={3} className=" mt-lg-2">
                <div className="subject-span">Expertise:</div>
              </Col>
              <Col lg={9}>
                <div className=" mt-lg-2">{expertise}</div>
              </Col>
              <Col lg={3} className=" mt-lg-2">
                <div className="subject-span">Referral ID:</div>
              </Col>
              <Col lg={9}>
                <div className=" mt-lg-2">{referral_id}</div>
              </Col>
              <Col lg={3} className=" mt-lg-2">
                <div className="subject-span">Referral Count:</div>
              </Col>
              <Col lg={9}>
                <div className=" mt-lg-2">{referral_count}</div>
              </Col>
              </Row>
          </Card.Text>
          <div className="faculty-edit-delete">
            <Popup
              className="add-popup-container"
              trigger={
                <button className="faculty-edit">
                  <MdEdit />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="add-course-div">
                  <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                    <Box className="popup-box">
                      <div className="heading">Edit Faculty Details</div>
                      <div className="course-container">
                        <TextField
                          className="textfields"
                          name="faculty_name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          value={editedFaculty.faculty_name}
                          onChange={handleFacultyChange}
                          error={!!validationErrors.faculty_name}
                          helperText={validationErrors.faculty_name}
                        />
                        <TextField
                          className="textfields"
                          name="faculty_expertise"
                          label="Expertise"
                          variant="outlined"
                          size="small"
                          value={editedFaculty.faculty_expertise}
                          onChange={handleFacultyChange}
                          error={!!validationErrors.faculty_expertise}
                          helperText={validationErrors.faculty_expertise}
                        />
                      
                        <TextField
                          className="textfields"
                          name="faculty_description"
                          label="Description"
                          variant="outlined"
                          multiline
                          rows={3}
                          size="small"
                          value={editedFaculty.faculty_description}
                          onChange={handleFacultyChange}
                          error={!!validationErrors.faculty_description}
                          helperText={validationErrors.faculty_description}
                        />
                        <label className="add-file-upload">
                          <input
                            className="add-img-file"
                            id="file"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <MdOutlineFileUpload className="upload-icon" />
                        </label>
                        {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                        {validationErrors.faculty_image && (
                            <div className="error-message">{validationErrors.faculty_image}</div>
                          )}
                      </div>
                      <div className="submit-banner-button-container">
                        <button type="submit">Submit</button>
                        <button type="button" onClick={close}>
                          Cancel
                        </button>
                      </div>
                    </Box>
                  </form>
                </div>
              )}
            </Popup>

            <Popup
              className="popup-container"
              contentStyle={{
                borderRadius: "20px",
                backgroundColor: "white",
              }}
              trigger={
                <button className="faculty-delete">
                  <MdDeleteForever />
                </button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="delete-img-div">
                  <h3 className="delete-img-heading">Are you sure?</h3>
                  <p className="delete-img-p">Do you really want to delete?</p>
                  <div className="delete-btn-div">
                    <button onClick={handleConfirmDelete}>Delete</button>
                    <button onClick={close}>Cancel</button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FacultyComponent;
