
import React, { useState } from "react";
import ExamScreen from "./exam/exam_screen";
import QuestionExam from "./question_exam/question_exam";


const MainExam = () => {
  const [currentPage, setCurrentPage] = useState("exam");
  const [selectedMcqId, setSelectedMcqId] = useState(null);

  const handlePageChange = (page, examId = null) => {
    setCurrentPage(page);
    setSelectedMcqId(examId);
  };

  return (
    <div>
      {currentPage === "exam" && <ExamScreen onPageChange={handlePageChange} />}
      {currentPage === "subexam" && (
        <QuestionExam onPageChange={handlePageChange} examId={selectedMcqId}/>
      )}
    </div>
  );
};

export default MainExam;
