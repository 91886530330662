import React, { useState, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { baseUrl } from '../../../../network/constants/constants';
import config from '../../../../network/auth';
import axios from '../../../../network/api';
import Progress from './components/progress/progress';
import "./course_progres.css";
const CourseProgress = () => {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);

  const reloadFunction = async () => {
    setLoading(true);
    try {
      let allPurchasingData = [];
      let nextPageUrl = `${baseUrl}app/student-list/`;

      while (nextPageUrl) {
        const response = await axios.get(nextPageUrl, config);
        allPurchasingData = [...allPurchasingData, ...response.data.results];
        nextPageUrl = response.data.next;
      }
      setStudentDetails(allPurchasingData);
    } catch (error) {
      console.error("Error fetching purchased course list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpen(true); // Open the dialog when a row is clicked
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  useEffect(() => {
    reloadFunction();
  }, []);

  const columns = [
    { field: "student_id", headerName: "ID", width: 100 },
    { field: "student_name", headerName: "Student Name", width: 270 },
    { field: "student_class", headerName: "Student Class", width: 100 },
    { field: "student_email", headerName: "Student Email", width: 150 },
    { field: "student_phone", headerName: "Student Phone", width: 250 },
    { field: "student_address", headerName: "Student Address", width: 250 },
    { field: "student_referral_id", headerName: "Student Referral Id", width: 250 },
    { field: "student_parent_phone", headerName: "Parent Phone", width: 250 },
    { field: "student_parent_email", headerName: "Parent Email", width: 250 },
    { field: "student_institution_name", headerName: "Institution Name", width: 250 },
    { field: "student_institution_email", headerName: "Institution Email", width: 150 },
  ];

  return (
    <TabContext>
      <Box className="box-container">
        <DataGrid
        className='grid-data'
          pageSize={20}
          autoHeight
          rows={studentDetails}
          columns={columns}
          getRowId={(row) => row.student_id}
          loading={loading}
          onRowClick={handleRowClick}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className='exam-progress-head'>Exam Progress</DialogTitle>
        <DialogContent>
          {selectedRow && <Progress studentId={selectedRow.student_id} />}
        </DialogContent>
        <DialogActions>
          <button className='prg-cls' onClick={handleClose}> Close</button>
        </DialogActions>
      </Dialog>
    </TabContext>
  );
};

export default CourseProgress;












