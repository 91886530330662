import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import Popup from "reactjs-popup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import ChapterComponent from "./component/chapter_component";

const ChapterScreen = () => {
  const [loading, setLoading] = useState(false);
  const [chapterDetails, setChapterDetails] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [newChapterDetails, setNewChapterDetails] = useState({
    session: "",
    subject: "",
  });
  const [errors, setErrors] = useState({
    session: "",
    subject: "",
  });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewChapterDetails({
        ...newChapterDetails,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    };

    const handleSubjectChange = (e) => {
      setNewChapterDetails({
        ...newChapterDetails,
        subject: e.target.value,
      });
      setErrors({
        ...errors,
        subject: "",
      });
    };

    const handleSubmit = (event, close) => {
      event.preventDefault();

      // Validation logic
      const newErrors = {};
      if (!newChapterDetails.session) {
        newErrors.session = "Chapter Name is required";
      }
      if (!newChapterDetails.subject) {
        newErrors.subject = "Subject is required";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return; 
      }

      handleAddQuestionDetails(close);
    };

    const handleAddQuestionDetails = (close) => {
      const formData = new FormData();
      formData.append("session", newChapterDetails.session);
      formData.append("subject", newChapterDetails.subject);

      axios
        .post(baseUrl + `app/chapters/create/`, formData, config)
        .then((response) => {
          setChapterDetails([...chapterDetails, response.data]);
          setNewChapterDetails({
            session: "",
            subject: "",
          });
          close();
        })
        .catch((error) => {
          console.error("Add queation Error:", error);
        });
    };

    const handleEditChapterDetails = (
      chapterId,
      editedChapterDetails,
      close
    ) => {
      const formData = new FormData();
      formData.append("session", editedChapterDetails.session);
      formData.append("subject", editedChapterDetails.subject);

      axios
        .patch(baseUrl + `app/chapters/edit/${chapterId}/`, formData, config)
        .then((response) => {
          setChapterDetails((prevChapterDetails) =>
            prevChapterDetails.map((chapter) =>
              chapter.id === chapterId ? response.data : chapter
            )
          );
          fetchChapters();
          close();
        })
        .catch((error) => {
          console.error("Edit Note Error:", error);
        });
    };

    const deleteChapter = (chapterId) => {
      setLoading(true);

      axios
        .delete(`${baseUrl}app/chapters/delete/${chapterId}/`, config)
        .then((response) => {
          setChapterDetails((prevChapter) =>
            prevChapter.filter((chapter) => chapter.id !== chapterId)
          );
        })
        .catch((error) => {
          console.error("Chapter Error:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

  const fetchChapters = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/chapters/`, config)
      .then((response) => {
        // console.log(response.data);
        setChapterDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Note details Error:", error);
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, config)
      .then((response) => {
        const subjects = response.data.results.flatMap((course) =>
          course.course_subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.Subject_name,
          }))
        );
        setSubjectList(subjects);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subject list:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchChapters();
    fetchSubjectList();
  }, []);

  const groupChaptersBySubject = () => {
    if (!Array.isArray(chapterDetails)) {
      return {}; 
    }
    return chapterDetails.reduce((acc, chapters) => {
      const subjectId = chapters.subject;
      if (!acc[subjectId]) {
        acc[subjectId] = [];
      }
      acc[subjectId].push(chapters);
      return acc;
    }, {});
  };

  const groupedChapters = Array.isArray(chapterDetails)
  ? groupChaptersBySubject()
  : {};

  // console.log("Grouped Question:", groupedQuestions);

  return (
    <div className="main-container">
      <Container fluid>
        <h2 className="accordion-head m-3">Subjects with chapters</h2>
        {loading ? (
          <Spinner className="loader-spinner" />
        ) : Object.keys(groupedChapters).length > 0 ? (
          <Accordion>
            {Object.keys(groupedChapters).map((subjectId, idx) => {
              const subjectName =
                subjectList.find(
                  (subject) => subject.id === parseInt(subjectId)
                )?.name || "Unknown Subject";

              return (
                <Accordion.Item eventKey={idx.toString()} key={subjectId}>
                  <Accordion.Header>{subjectName}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {groupedChapters[subjectId].map((chapter) => (
                        <Col lg={6} key={chapter.id}>
                          <ChapterComponent
                            id={chapter.id}
                            key={chapter.id}
                            session={chapter.session}
                            subjectList={subjectList}
                            subjectId={chapter.subject}
                            subject_name={
                              subjectList.find(
                                (subject) => subject.id === chapter.subject
                              )?.name || ""
                            }
                            onEditChapter={handleEditChapterDetails}
                            onDelete={deleteChapter}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div>Q and is not available</div>
        )}

        <Popup
          className="add-popup-container"
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add Chapters</button>}
          modal
          nested
        >
          {(close) => (
            <div className="add-course-div">
              <form onSubmit={(e) => handleSubmit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Add Chapter Details</div>
                  <div className="course-container">
                    <TextField
                      className="textfields"
                      name="session"
                      label="Chapter Name"
                      variant="outlined"
                      size="small"
                        value={newChapterDetails.session}
                        onChange={handleInputChange}
                        disabled={loading}
                        error={!!errors.session}
                        helperText={errors.session}
                    />
                    <select
                      name="subject"
                      className="form-control"
                        value={newChapterDetails.subject}
                        onChange={handleSubjectChange}
                        disabled={loading}
                    >
                      <option value="">Select Subject</option>
                      {subjectList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.subject && <div className="error-text">{errors.subject}</div>}
                  </div>
                  <div className="submit-banner-button-container">
                    <button type="submit" disabled={loading}>
                      Submit
                    </button>
                    <button type="button" onClick={close} disabled={loading}>
                      Cancel
                    </button>
                  </div>
                </Box>
              </form>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default ChapterScreen;
