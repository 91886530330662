import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { baseUrl } from '../../../../../network/constants/constants';
import config from '../../../../../network/auth';
import axios from '../../../../../network/api';
import './exam_rec_new_order.css';
import { Spinner } from 'react-bootstrap';

export const ExamRecNew = ({ chapterId }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchExamRec = () => {
        setLoading(true);
        axios
            .get(`${baseUrl}app/chapter-by-id/${chapterId}/`, config)
            .then((response) => {
                const order = response.data.order;
                const combinedItems = order.map((item) => ({
                    id: item.id,
                    content: item.content,
                    type: item.type,
                    order: item.order,
                }));
                setItems(combinedItems);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Fetch Error:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchExamRec();
    }, [chapterId]);

    // Handle drag end logic
   // Handle drag end logic
const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    const updatedItems = reorderedItems.map((item, index) => ({
        ...item,
        order: index,
    }));

    setItems(updatedItems);
    postUpdatedOrder(updatedItems);
    console.log(updatedItems)
};

// Function to POST the updated order to the server
const postUpdatedOrder = async (updatedItems) => {
    try {
        const updatedOrder = updatedItems.map((item) => ({
            id: item.id,
            type: item.type,
            content: item.content,
            order: item.order,
        }));
        await axios.post(`${baseUrl}app/chapter-order-update/${chapterId}/`, updatedOrder, config);
        console.log("Order updated successfully!");
    } catch (error) {
        console.error("Error updating order:", error);
    }
};
    return (
        <div className="layout__wrapper">
          
                <DragDropContext onDragEnd={handleDragEnd}>

                    <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                        <Droppable droppableId="droppable-root">
                            {(provided) => (
                                <div
                                 className="exam-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        minHeight: "100px",
                                        border: "1px solid #ccc",
                                        padding: "10px",
                                        backgroundColor: "#f9f9f9"
                                    }}
                                >
                                    {loading ? (
                                        <Spinner className="loader-spinner"/>
                                    ) : items.length === 0 ? (
                                        <p>No Recorded class and Exam available</p>
            
                                    ) : items.length > 0 ? (
                                        items.map((item, index) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="exam-item" 
                                                    >
                                                        <h3 className="exam-name">{item.content}</h3>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    ) : (
                                        <p>No Recorded classes and Exams available</p>
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
    
        </div>
    );
};




