import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./note_screen.css";
import NoteComponent from "./component/note_component";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import Popup from "reactjs-popup";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { MdOutlineFileUpload } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const NoteScreen = () => {
  const [loading, setLoading] = useState(false);
  const [noteDetails, setNoteDetails] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [newNoteDetails, setNewNoteDetails] = useState({
    note_name: "",
    subject: "",
    file: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    note_name: "",
    subject: "",
    file: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {
      note_name: "",
      subject: "",
      file: "",
    };

    if (!newNoteDetails.note_name) {
      errors.note_name = "Note name is required.";
      isValid = false;
    }

    if (!newNoteDetails.subject) {
      errors.subject = "Subject is required.";
      isValid = false;
    }

    if (!newNoteDetails.file) {
      errors.file = "A PDF file is required.";
      isValid = false;
    } else if (!newNoteDetails.file.name.match(/\.pdf$/)) {
      errors.file = "File must be a PDF.";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setNewNoteDetails({
      ...newNoteDetails,
      [name]: value,
    });
  };

  const handleSubjectChange = (e) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      subject: "",
    }));

    setNewNoteDetails({
      ...newNoteDetails,
      subject: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      file: "",
    }));
    setNewNoteDetails({
      ...newNoteDetails,
      file: e.target.files[0],
    });
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      handleAddNoteDetails(close);
    }
  };

  const handleAddNoteDetails = (close) => {
    const formData = new FormData();
    formData.append("note_name", newNoteDetails.note_name);
    formData.append("subject", newNoteDetails.subject);
    formData.append("file", newNoteDetails.file);
    formData.append("link_type", "ext_link");

    axios
      .post(baseUrl + `app/notes/`, formData, config)
      .then((response) => {
        setNoteDetails([...noteDetails, response.data]);
        setNewNoteDetails({
          note_name: "",
          subject: "",
          file: null,
        });
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Add Note Error:", error);
        setLoading(false);
      });
  };

  const handleEditNoteDetails = (noteId, editedNoteDetails, close) => {
    const formData = new FormData();
    formData.append("note_name", editedNoteDetails.note_name);
    formData.append("subject", editedNoteDetails.subject);

    if (editedNoteDetails.file instanceof File) {
      formData.append("file", editedNoteDetails.file);
    }

    formData.append("link_type", "ext_link");

    axios
      .patch(
        `${baseUrl}app/notes-get-update-delete/${noteId}/`,
        formData,
        config
      )
      .then((response) => {
        setNoteDetails((prevNoteDetails) =>
          prevNoteDetails.map((note) =>
            note.note_id === noteId ? response.data : note
          )
        );
        setNewNoteDetails({
          note_name: "",
          subject: "",
          file: null,
        });
        close();
      })
      .catch((error) => {
        console.error("Edit Note Error:", error);
      });
  };

  const deleteNote = (noteId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/notes-get-update-delete/${noteId}/`, config)
      .then((response) => {
        setNoteDetails((prevNote) =>
          prevNote.filter((note) => note.id !== noteId)
        );
      })
      .catch((error) => {
        console.error("Exam Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchNotes = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/notes/`, config)
      .then((response) => {
        setNoteDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Note details Error:", error);
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, config)
      .then((response) => {
        const subjects = response.data.results.flatMap((course) =>
          course.course_subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.Subject_name,
          }))
        );
        setSubjectList(subjects);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subject list:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchNotes();
    fetchSubjectList();
  }, []);

  const groupNotesBySubject = () => {
    return noteDetails.reduce((acc, note) => {
      const subjectId = note.subject;
      if (!acc[subjectId]) {
        acc[subjectId] = [];
      }
      acc[subjectId].push(note);
      return acc;
    }, {});
  };

  const groupedNotes = groupNotesBySubject();

  return (
    <div className="main-container">
      <Container fluid>
        <h2 className="accordion-head m-3">Subjects with Notes</h2>
        {loading ? (
          <Spinner className="loader-spinner" />
        ) : Object.keys(groupedNotes).length > 0 ? (
          <Accordion>
            {Object.keys(groupedNotes).map((subjectId, idx) => {
              const subjectName =
                subjectList.find(
                  (subject) => subject.id === parseInt(subjectId)
                )?.name || "Unknown Subject";

              return (
                <Accordion.Item eventKey={idx.toString()} key={subjectId}>
                  <Accordion.Header>{subjectName}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {groupedNotes[subjectId].map((note) => (
                        <Col lg={6} key={note.note_id}>
                          <NoteComponent
                            id={note.note_id}
                            key={note.note_id}
                            name={note.note_name}
                            subjectId={note.subject}
                            subject_name={
                              subjectList.find(
                                (subject) => subject.id === note.subject
                              )?.name || ""
                            }
                            pdf={note.file}
                            subjectList={subjectList}
                            onEditNote={handleEditNoteDetails}
                            onDelete={deleteNote}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div>No Notes available</div>
        )}

        <Popup
          className="add-popup-container"
          contentStyle={{ borderRadius: "20px" }}
          trigger={<button className="btn-add">+ Add Note</button>}
          modal
          nested
        >
          {(close) => (
            <div className="add-course-div">
              <form onSubmit={(e) => handleSubmit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Add Note Details</div>
                  <div className="course-container">
                    <TextField
                      className="textfields"
                      name="note_name"
                      label="Note Name"
                      variant="outlined"
                      size="small"
                      value={newNoteDetails.note_name}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!validationErrors.note_name}
                      helperText={validationErrors.note_name}
                    />
                    <select
                      name="subject"
                      className="form-control"
                      value={newNoteDetails.subject}
                      onChange={handleSubjectChange}
                      disabled={loading}
                    >
                      <option value="">Select Subject</option>
                      {subjectList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.subject && (
                      <div className="error-text">
                        {validationErrors.subject}
                      </div>
                    )}
                    <label className="add-file-upload">
                      <input
                        type="file"
                        id="file"
                        className="add-img-file"
                        name="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        disabled={loading}
                      />
                      <MdOutlineFileUpload className="upload-icon" />
                    </label>
                    {validationErrors.file && (
                      <div className="error-text">{validationErrors.file}</div>
                    )}
                  </div>
                  <div className="submit-banner-button-container">
                    <button
                      className="btn-post"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving..." : "Submit"}
                    </button>
                    <button type="button" onClick={close} disabled={loading}>
                      Cancel
                    </button>
                  </div>
                </Box>
              </form>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default NoteScreen;
