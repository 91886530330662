import React, { useState } from 'react';
import CourseScreen from './course_screen/course_screen';
import SubjectScreen from './subject_screen/subject_screen';

const MainScreen = () => {
    const [currentPage, setCurrentPage] = useState("course");
    const [selectedCourseId, setSelectedCourseId] = useState();

    const handlePageChange = (page, courseId ) => {
        setCurrentPage(page);
        setSelectedCourseId(courseId);
    };

    return (
        <div>
            {currentPage === "course" && <CourseScreen onPageChange={handlePageChange} />}
            {currentPage === "subject" && (
                <SubjectScreen onPageChange={handlePageChange} courseId={selectedCourseId} />
            )}
        </div>
    );
};

export default MainScreen;
















