import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./q_n_a_component.css";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import Box from "@mui/material/Box";
import Popup from "reactjs-popup";
import { TextField } from "@mui/material";

const QuestionComponent = ({
  id,
  question,
  answer,
  subjectId,
  subject_name,
  subjectList,
  onEditNote,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [editingQa, setEditingQa] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedQaDetails, setEditedQaDetails] = useState({
    qanda_question: question,
    qanda_answer: answer,
    subject: subjectId,
  });
  const [errors, setErrors] = useState({
    qanda_question: "",
    qanda_answer: "",
    subject: "",
  });
  const handleQaChange = (event) => {
    const { name, value } = event.target;
    setEditedQaDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
  };

  const handleSubmitEdit = (event, close) => {
    event.preventDefault();

    // Validation logic
    const newErrors = {};
    if (!editedQaDetails.qanda_question) {
      newErrors.qanda_question = "Question is required";
    }
    if (!editedQaDetails.qanda_answer) {
      newErrors.qanda_answer = "Answer is required";
    }
    if (!editedQaDetails.subject) {
      newErrors.subject = "Subject is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop form submission if there are validation errors
    }

    onEditNote(id, editedQaDetails, close);
    setEditingQa(false);
  };


  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  return (
    <Card className="note-card">
      <Card.Body>
        <Card.Text className="note">
          <Row>
            <Col lg={4} className=" mt-lg-1">
              <div className="course-span">Question:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-1">{question}</div>
            </Col>
            <Col lg={4} className=" mt-lg-2">
              <div className="course-span">Answer:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-2">{answer}</div>
            </Col>
            <Col lg={4} className=" mt-lg-2">
              <div className="course-span">Subject:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-2">{subject_name}</div>
            </Col>
          </Row>
        </Card.Text>
        <div className="note-edit-delete">
          <Popup
            className="add-popup-container"
            trigger={
              <button className="course-edit">
                <MdEdit />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form
                onSubmit={(e) => handleSubmitEdit(e, close)}
                >
                  <Box className="popup-box">
                    <div className="heading">Edit Question Details</div>
                    <div className="course-container">
                      <TextField
                        className="textfields"
                        name="qanda_question"
                        label="Question"
                        variant="outlined"
                        size="small"
                        value={editedQaDetails.qanda_question}
                        onChange={handleQaChange}
                        disabled={loading}
                        error={!!errors.qanda_question}
                        helperText={errors.qanda_question}
                      />
                      <TextField
                        className="textfields"
                        name="qanda_answer"
                        label="Answer"
                        variant="outlined"
                        size="small"
                        value={editedQaDetails.qanda_answer}
                        onChange={handleQaChange}
                        disabled={loading}
                        error={!!errors.qanda_answer}
                        helperText={errors.qanda_answer}
                      />
                      <select
                        name="subject"
                        className="form-control"
                        value={editedQaDetails.subject}
                        onChange={handleQaChange}
                        disabled={loading}
                      >
                        <option value="">Select Subject</option>
                        {subjectList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {errors.subject && <div className="error-text">{errors.subject}</div>}
                    </div>
                    <div className="submit-banner-button-container">
                      <button
                        type="submit"
                          disabled={loading}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={close}
                          disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>
          <Popup
            className="popup-container"
            contentStyle={{
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            trigger={
              <button className="course-delete">
                <MdDeleteForever />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">Are you sure?</h3>
                <p className="delete-img-p">Do you really want to delete?</p>
                <div className="delete-btn-div">
                  <button
                    onClick={handleConfirmDelete}
                  >
                    Delete
                  </button>
                  <button onClick={close}>Cancel</button>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default QuestionComponent;
