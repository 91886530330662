import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "./note_component.css";
import { MdEdit, MdDeleteForever, MdOutlineFileUpload } from "react-icons/md";
import Box from "@mui/material/Box";
import Popup from "reactjs-popup";
import { TextField } from "@mui/material";

const NoteComponent = ({
  id,
  name,
  subjectId,
  subject_name,
  pdf,
  subjectList,
  onEditNote,
  onDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editedNoteDetails, setEditedNoteDetails] = useState({
    note_name: name,
    subject: subjectId,
    file: pdf,
  });
  const [validationErrors, setValidationErrors] = useState({
    note_name: "",
    subject: "",
    file_pdf: "",
    
  });
  const validateForm = () => {
    let isValid = true;
    const errors = {
      note_name: "",
      subject: "",
      file: "",
    };

    if (!editedNoteDetails.note_name) {
      errors.note_name = "Note name is required.";
      isValid = false;
    }

    if (!editedNoteDetails.subject) {
      errors.subject = "Subject is required.";
      isValid = false;
    }

    if (!editedNoteDetails.file) {
      errors.file = "File is required.";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };
  
  const handleNoteChange = (event) => {
    const { name, value } = event.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setEditedNoteDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    
    setEditedNoteDetails((prevDetails) => ({
      ...prevDetails,
      file: event.target.files[0],
    }));
  };

  const handleSubmitEdit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
    onEditNote(id, editedNoteDetails, close);
    setEditingNote(false);
    }
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setDeleteConfirmationOpen(false);
  };

  const url = pdf;
  const [pdfUrl] = url.split("?");
  const trimFrom = 40;
  const trimTo = 100;
  const trimmedPdfUrl =
    pdfUrl.length > trimTo
      ? pdfUrl.slice(0, trimFrom) + "..." + pdfUrl.slice(trimTo)
      : pdfUrl;

  return (
    <Card className="note-card">
      <Card.Body>
        <Card.Text className="note">
          <Row>
            <Col lg={4} className=" mt-lg-1">
              <div className="course-span">Note Name:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-1">{name}</div>
            </Col>
            <Col lg={4} className=" mt-lg-2">
              <div className="course-span">Subject:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-2">{subject_name}</div>
            </Col>
            <Col lg={4} className=" mt-lg-2">
              <div className="course-span">Note Pdf:</div>
            </Col>
            <Col lg={8}>
              <div className=" mt-lg-2">
                <a href={pdf}>{trimmedPdfUrl}</a>
              </div>
            </Col>
          </Row>
        </Card.Text>
        <div className="note-edit-delete">
          <Popup
            className="add-popup-container"
            trigger={
              <button className="course-edit">
                <MdEdit />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form onSubmit={(e) => handleSubmitEdit(e, close)}>
                  <Box className="popup-box">
                    <div className="heading">Edit Note Details</div>
                    <div className="course-container">
                      <TextField
                        className="textfields"
                        name="note_name"
                        label="Note Name"
                        variant="outlined"
                        size="small"
                        value={editedNoteDetails.note_name}
                        onChange={handleNoteChange}
                        disabled={loading}
                        error={!!validationErrors.note_name}
                        helperText={validationErrors.note_name}
                      />
                      <select
                        name="subject"
                        className="form-control"
                        value={editedNoteDetails.subject}
                        onChange={handleNoteChange}
                        disabled={loading}
                      >
                        <option value="">Select Subject</option>
                        {subjectList.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      {validationErrors.subject && (
                      <div className="error-text">
                        {validationErrors.subject}
                      </div>
                    )}
                      <label className="add-file-upload">
                        <input
                          className="add-img-file"
                          name="file"
                          type="file"
                          onChange={handleFileChange}
                          disabled={loading}
                        />
                        <MdOutlineFileUpload className="upload-icon" />
                      </label>
                      {validationErrors.file_pdf && (
                      <div className="error-text">
                        {validationErrors.file_pdf}
                      </div>
                    )}
                    </div>
                    <div className="submit-banner-button-container">
                      <button type="submit" disabled={loading}>
                        Submit
                      </button>
                      <button type="button" onClick={close} disabled={loading}>
                        Cancel
                      </button>
                    </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>
          <Popup
            className="popup-container"
            contentStyle={{
              borderRadius: "20px",
              backgroundColor: "white",
            }}
            trigger={
              <button className="course-delete">
                <MdDeleteForever />
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="delete-img-div">
                <h3 className="delete-img-heading">Are you sure?</h3>
                <p className="delete-img-p">Do you really want to delete?</p>
                <div className="delete-btn-div">
                  <button onClick={handleConfirmDelete}>Delete</button>
                  <button onClick={close}>Cancel</button>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default NoteComponent;



