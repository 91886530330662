import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import axios from "../../../../network/api";
import { ExamRecNew } from "./exam_rec_new_order/exam_rec_new_order";
import './chap_new_order.css';
import { Spinner } from "react-bootstrap";

export const ChapterNewOrder = ({ subjectId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (id) => {
    setSelectedChapterId(id);
    setOpen(true);
  };

  useEffect(() => {
    fetchSubjects();
  }, [subjectId]);

  const fetchSubjects = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/subject-complete-subjectid/${subjectId}/`, config)
      .then((response) => {
        const order = response.data.order;
        const combinedItems = order.map((item) => ({
          id: item.id,
          name: item.name,
          order: item.order,
        }));
        setItems(combinedItems);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Subjects Error:", error);
        setLoading(false);
      });
  };

  // Handle drag and drop logic
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    const updatedItems = reorderedItems.map((item, index) => ({
      id: item.id,
      name: item.name,
      order: index, 
    }));

    setItems(updatedItems);
    postUpdatedOrder(updatedItems); 
    console.log(updatedItems);
  };

  // Function to POST the updated order to the server
  const postUpdatedOrder = async (updatedItems) => {
    try {
      
      await axios.post(`${baseUrl}app/subject-order-update/${subjectId}/`, 
        updatedItems, 
        config
      );
      console.log("Order updated successfully!");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  return (
    <div >
      <div className="card">
        
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-root">
            {(provided) => (
              <div
              className="chapter-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ 
                  minHeight: "100px", 
                  padding: "10px", 
                  border: "1px solid #ccc", 
                  overflowY: "auto", 
                  maxHeight: "300px" 
                }}
              >
                {loading ? (
                  <Spinner className="loader-spinner"/>
                ) : items.length === 0 ? (
                  <p>No chapters available</p>
                ) : items.length > 0 ? (
                  items.map((store, index) => (
                    <Draggable
                      key={store.id}
                      draggableId={store.id.toString()}
                      index={index}
                    >
                      
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="chapter-item" 
                          onClick={() => handleClick(store.id)}
                        >
                          <h3 className="chapter-name" >
                            {store.name}
                          </h3>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p>No chapters available</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="rec-exam-order-head mb-2">
          Rearrange Recorded Classes and Exams
        </DialogTitle>
        <DialogContent>
          {selectedChapterId && <ExamRecNew chapterId={selectedChapterId} />}
        </DialogContent>
        <DialogActions>
          <button className="prg-cls" onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
