import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./refferal.css";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import dayjs from 'dayjs';
const Refferal = () => {
  const [referralReport, setReferralReport] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const fetchRefferalList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/referral-report/`, config)
      .then((response) => {
        setReferralReport(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching referral list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRefferalList();
  }, []);

  useEffect(() => {
    const result = referralReport.reduce((acc, faculty) => {
      if (faculty.faculty_name === selectedFaculty) {
        return [...acc, ...faculty.students];
      }
      return acc;
    }, []);
    setFilteredStudents(
      result.map((student, index) => ({
        ...student,
        id: index + 1, 
      }))
    );
  }, [selectedFaculty, referralReport]);

  //
  const handleFilter = () => {};
  //
  const handleReset = () => {};

  const exportToExcel = () => {
    const studentsForExport = filteredStudents.map((student) => ({
      id: student.id,
      student_name: student.student_name,
      student_email: student.student_email,
      student_class: student.student_class,
      student_address: student.student_address,
      student_phone: student.student_phone,
      student_parent_phone: student.student_parent_phone,
      student_parent_email: student.student_parent_email,
      student_referral_id: student.student_referral_id,
      student_institution_name: student.student_institution_name,
      student_institution_email: student.student_institution_email,
    }));
    const worksheet = XLSX.utils.json_to_sheet(studentsForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "refferalid-studentlist.xlsx");
  };

  return (
    <div>
      <h2 className="faculty-refferal-head">Faculty Referral Search</h2>
      <FormControl className="form-control-refferal">
        <InputLabel id="faculty-select-label">Select Faculty Name</InputLabel>
        <Select
          labelId="faculty-select-label"
          id="faculty-select"
          value={selectedFaculty}
          onChange={(e) => setSelectedFaculty(e.target.value)}
          label="Faculty Name"
        >
          <MenuItem value="">Select Faculty</MenuItem>
          {referralReport.map((faculty) => (
            <MenuItem
              key={faculty.faculty_referral_id}
              value={faculty.faculty_name}
            >
              {faculty.faculty_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className="box-container">
        <div className="data-grid">
          <div className="download-excel">
            <div className="date-input-container">
              <input
                className="date-input"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <input
                className="date-input"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
              />
              <button className="filter-btn" onClick={handleFilter}>
                Apply Range
              </button>
              <button className="reset-btn" onClick={handleReset}>
                Reset
              </button>
            </div>
            <Button
              variant="contained"
              className="d-flex justify-content-center"
              color="primary"
              onClick={exportToExcel}
              style={{ marginBottom: "10px" }}
            >
              Export as Excel
            </Button>
          
          </div>
          <DataGrid
            rows={filteredStudents}
            columns={[
              { field: "id", headerName: "Student Id", width: 90 },
              { field: "student_name", headerName: "Student Name", width: 150 },
              {
                field: "student_email",
                headerName: "Student Email",
                width: 200,
              },
              { field: "student_class", headerName: "Class", width: 100 },
              { field: "student_address", headerName: "Address", width: 200 },
              { field: "student_phone", headerName: "Phone", width: 150 },
              {
                field: "student_parent_phone",
                headerName: "Parent Phone",
                width: 150,
              },
              {
                field: "student_parent_email",
                headerName: "Parent Email",
                width: 200,
              },
              {
                field: "student_referral_id",
                headerName: "Student Referral Id",
                width: 200,
              },
              {
                field: "initially_purchased_course",
                headerName: "Initially Purchased Course",
                width:250
              },
              {
                field: "purchase_date",
                headerName: "Purchase Date",
                width: 150,
                // valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
              },
              {
                field: "student_institution_name",
                headerName: "Institution Name",
                width: 150,
              },
              {
                field: "student_institution_email",
                headerName: "Institution Email",
                width: 200,
              },
              {
                field: "country",
                headerName: "Country",
                width: 150
              }
            ]}
            pageSize={5}
          />
        </div>
      </Box>
    </div>
  );
};

export default Refferal;
